import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import SyncIcon from '@mui/icons-material/Sync';
import Image from '@mui/icons-material/Image';


const base64 = require('js-base64');

const url_base = "https://lilith-cat-release.lilithgame.com/queue-api";
const project = "cat1";
const user_q = "user_msg";
const token = "xZ5yXTRs8Jddddaszhn";
const delay = 0;
const ttl = 12000;
const tries = 3;
const ttr = 600;

function App() {
  const [data, setData] = useState(null);
  const [reply, setReply] = useState("");
  const [taskid, setTaskid] = useState(null);
  const [tips, setTips] = useState("");
  const [cnt, setCnt] = useState(0);
  const [key, setKey] = useState("");
  const [poscard, setPoscard] = useState(null);
  const [bgfile, setBGfile] = useState("postcard/1.jpg");

  const mainStyle = {
    backgroundImage: "url(/bg.jpg)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "30px",
    paddingBottom: "60px",
    fontFamily: "KaiTi",
    //position: "fixed",
    //right: "10px",
    textAlign: "center",
    width: "80%"
  };

  const buttonStyle = {
    position: "fixed",
    right: "10px"
  };

  function answer(event){

    if (key == null) {
      setTips("key is null");
      return;
    }

    if (key.length !== 5){
      setTips("Invalid Key");
      return;
    }

    if (data == null) {
      setTips("Get message first!");
      return;
    }

    if (reply == null) {
      setTips("reply is null");
      return;
    }

    if (reply.length < 5){
      setTips("At least 5 letters.");
      return;
    }
    
    fetch(`${url_base}/${project}/${data.openid}?delay=${delay}&ttl=${ttl}&tries=${tries}`,{
      method: 'PUT',
      headers: {
        "X-token": token + key
        },
      body: JSON.stringify({
          "openid": data.openid,
          "msg": reply,
          "send_sid": 0,   // u32
          "resp_sid": 1,   // u32
      })
    })

    fetch(`${url_base}/${project}/${user_q}/delete/${taskid}`,{
      method: 'DELETE',
      headers: {
        "X-token": token + key
        }
    })

    setReply("");
    setTips("SENT");
    setData(null);
    setTaskid(null);

    event.preventDefault();
  }

  function poscardMap(json){
    let postMap = new Map();
    for (let v in json){
      postMap[json[v].sid] = json[v].message;
    }
    setPoscard(postMap);
  }
  
  useEffect(() => {
    fetch("postcard_default.json")
    .then((response) =>response.json())
    .then(json => poscardMap(json))
    .catch((error) => {console.error(error)});
  },[]
  );

  useEffect(() => {
    if (key.length !== 5){
      setTips("Invalid Key");
      return;
    }

    fetch(`${url_base}/${project}/${user_q}?ttr=${ttr}`,{
      method: 'GET',
      headers: {
      "X-token": token + key
      }
    })
      .then(response => response.json())
      .then(json => {
        let d = JSON.parse(base64.decode(json.data));
        setData(d); 
        setTaskid(json.task_id); 
        setReply(poscard[d.resp_sid]);
        setBGfile("postcard/" + d.resp_sid + ".jpg")
        setTips("New Msg")})
      .catch(error => {console.error(error); setData(null); setTaskid(null); setTips("NO New Msg")});
  }, [cnt]);

  return (
    <div style={{
      backgroundImage: `url(/${bgfile})`,
      backgroundSize: "30%",
      backgroundRepeat: "no-repeat",
      padding: "30px",
      paddingBottom: "60px",
      fontFamily: "KaiTi",
      //position: "fixed",
      //right: "10px",
      textAlign: "center",
      width: "80%"
      }}>
      <div>
        
        <TextField
          id="filled-password-input"
          label="KEY"
          type="password"
          variant="outlined"
          value={key} onChange={(event)=>setKey(event.target.value)} 
        />        
      </div>
      <div>
      <Box>
        <TextField 
          sx={{ width: '100%', maxWidth: 500 , fontVariantEastAsian: true }}
          label = "铲屎官的来信："
          multiline
          variant='filled'
          value={data ? data.msg : ''}
          contentEditable='false'
        >
        
        </TextField>
      
      </Box>
      </div>
      <Box>
      
      </Box>
      <Box >
        <TextField
        sx={{ width: '100%', maxWidth: 500 , fontVariantEastAsian: true }}
        label="可爱的🐱的回信："
        multiline
        minRows={10}
        maxRows={10}
        variant="filled"
        name="i" value={reply} onChange= {(event) => {setReply(event.target.value)}}
        />
      </Box>
       
      <Box>
        <Stack direction="row" spacing={4} marginLeft='40%'>
        <Button variant="outlined" 
          endIcon={<SyncIcon/>}
          onClick={()=>setCnt((cnt)=>cnt+1)}>next</Button>

          <Button variant='outlined' 
          endIcon={<SendIcon />}
          onClick={answer}>SEND</Button>
        </Stack>
       
      </Box>
      <div>
        <br />
        tips: {tips}
        <br />
        {bgfile}
      </div>
      
    </div>
  );
}

export default App;